import {
    Checkbox,
    Grid,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core';
import { Help } from '@material-ui/icons';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import { ChangeEvent, ComponentProps } from 'react';

import { ClearableTextField } from 'src/components/common/inputs/ClearableTextField';
import { LabelTooltip } from 'src/components/common/TaxonomyDiscovery/LabelTooltip';
import { TaxonomyIntent } from 'src/constants';

export interface PropTypes extends Omit<ComponentProps<typeof Grid>, 'id' | 'onChange'> {
    id: string | null;
    taxonomyName: string;
    canBeQvFilter: boolean;
    isFacetedTaxonomy: boolean;
    intent?: string;
    onNameChange: (taxonomyName: string) => void;
    onIntentChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onQvFilterChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles(() => ({
    checkbox: {
        padding: 0,
    },
}));

export const TaxonomyDetailsForm = (props: PropTypes): JSX.Element => {
    const {
        id,
        taxonomyName,
        intent,
        canBeQvFilter,
        isFacetedTaxonomy,
        onNameChange,
        onIntentChange,
        onQvFilterChange,
        ...rest
    } = props;

    const classes = useStyles();
    const canBeQvTooltip = isFacetedTaxonomy
        ? 'This taxonomy cannot be used as a QuickView filter because it is faceted.'
        : 'Check this box if you wish to use this taxonomy as a filter in Gallery QuickView, such as Greeting in holiday cards';

    return (
        <Grid container direction="column" spacing={6} {...rest}>
            <Grid item>
                <TextField
                    disabled
                    fullWidth
                    InputLabelProps={{ shrink: id !== null }}
                    label="ID"
                    size="small"
                    value={id}
                />
            </Grid>
            <Grid container item spacing={4}>
                <Grid item xs>
                    <ClearableTextField
                        fullWidth
                        required
                        label="Taxonomy Name"
                        size="small"
                        value={taxonomyName}
                        onChange={onNameChange}
                    />
                </Grid>
            </Grid>
            <Grid container item alignItems="flex=start" direction="row" flexWrap="nowrap" spacing={4}>
                <Grid item xs>
                    <TextField
                        fullWidth
                        required
                        select
                        helperText="The intent of the taxonomy"
                        id="taxonomy-intent-select"
                        key={intent}
                        label="Intent"
                        size="small"
                        value={intent}
                        variant="outlined"
                        onChange={onIntentChange}
                    >
                        {Object.values(TaxonomyIntent).map((i) => (
                            <MenuItem key={i.toString()} value={i.toString()}>
                                {i.toString()}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid container item xs direction="row" spacing={1}>
                    <Grid item>
                        <Typography>Can be QuickView Filter</Typography>
                    </Grid>
                    <Grid item>
                        <Checkbox
                            checked={canBeQvFilter}
                            className={classes.checkbox}
                            color="primary"
                            disabled={isFacetedTaxonomy}
                            onChange={onQvFilterChange}
                        />
                    </Grid>
                    <Grid item overflow="visible">
                        <LabelTooltip title={canBeQvTooltip}>
                            <Help />
                        </LabelTooltip>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
