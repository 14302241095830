import {
    Button,
    Drawer,
    IconButton,
    makeStyles,
    PaperProps as MuiPaperProps,
    Theme,
    Tooltip,
} from '@material-ui/core';
import { Cancel, Close } from '@material-ui/icons';
import clsx from 'clsx';
import { ComponentProps, MouseEvent, useState } from 'react';

import { CSSGrid } from 'src/components/common/CSSGrid';
import { useRecoilState } from 'recoil';
import { drawerUpdatedState } from 'src/atoms/drawerUpdatedAtom';
import { UpdateFooter } from 'src/components/Taxonomies/TaxonomyDetailsDrawer/UpdateFooter';
import { CreateFooter } from 'src/components/Taxonomies/TaxonomyDetailsDrawer/CreateFooter';

export interface PropTypes extends Omit<ComponentProps<typeof Drawer>, 'id'> {
    id: string | null;
    taxonomyName: string;
    taxonomyNode: Models.ContentStoreApi.V3.TaxonomyNode[];
    canBeQvFilter: boolean;
    intent?: string;
    setId: (id: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    actions: {
        backgroundColor: theme.palette.common.white,
        bottom: 0,
        gap: theme.spacing(4),
        justifyContent: 'end',
        gridAutoFlow: 'column dense',
        position: 'sticky',
        paddingBottom: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingTop: theme.spacing(5),
        marginLeft: theme.spacing(-5),
        marginRight: theme.spacing(-5),
    },
    closeButton: {
        position: 'absolute',
        top: theme.spacing(6),
        right: theme.spacing(5),
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        padding: `${theme.spacing(6)} ${theme.spacing(6)} 0 ${theme.spacing(6)}`,
        maxWidth: '100%',
        minWidth: '100%',
        overflowY: 'hidden',
        width: '100%',
        [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
            maxWidth: theme.spacing(200),
            minWidth: theme.spacing(180),
            width: 'auto',
        },
        backgroundColor: '#f8f8f8',
        display: 'grid',
        gridAutoFlow: 'row',
        gridTemplateRows: '1fr auto',
    },
    scrollContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0,
        },
        overflowY: 'scroll',
    },
    enabledToggle: {
        position: 'absolute',
    },
}));

export const TaxonomyDrawer = (props: PropTypes): JSX.Element => {
    const {
        children,
        id,
        taxonomyName,
        taxonomyNode,
        canBeQvFilter,
        intent,
        setId,
        onClose,
        PaperProps = {} as Partial<MuiPaperProps>,
        ...rest
    } = props;
    const classes = useStyles();
    const { className: paperPropsClassName, ...paperPropsRest } = PaperProps;
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [updatedState, setUpdatedState] = useRecoilState(drawerUpdatedState);

    const handleClose = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        if (updatedState) {
            setModalOpen(true);
        } else if (onClose) {
            onClose(event, 'escapeKeyDown');
        }
    };

    const onModalClose = (event?: MouseEvent<HTMLButtonElement>): void => {
        setModalOpen(false);
        setUpdatedState(false);
        if (onClose && event) {
            onClose(event, 'escapeKeyDown');
        }
    };

    const handleSave = (): Promise<void> => {
        setUpdatedState(false);

        return Promise.resolve();
    };

    const handleAccept = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        if (onClose) {
            onClose(event, 'escapeKeyDown');
        }

        setModalOpen(false);
    };

    return (
        <Drawer
            anchor="right"
            PaperProps={{
                className: clsx(classes.drawer, paperPropsClassName),
                ...paperPropsRest,
            }}
            onClose={handleClose}
            {...rest}
        >
            <div className={classes.scrollContainer}>
                <Tooltip className={classes.closeButton} title="close panel">
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Tooltip>
                {children}
            </div>
            <CSSGrid className={classes.actions}>
                <Tooltip title="cancel">
                    <Button
                        color="secondary"
                        startIcon={<Cancel />}
                        variant="outlined"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </Tooltip>
                { id && (
                <UpdateFooter
                    canBeQvFilter={canBeQvFilter}
                    confirmationDialogOpened={modalOpen && updatedState}
                    id={id}
                    intent={intent}
                    setId={setId}
                    taxonomyName={taxonomyName}
                    taxonomyNode={taxonomyNode}
                    onAccept={handleAccept}
                    onClose={onModalClose}
                    onModalClose={onModalClose}
                    onSave={handleSave}
                />
                )}
                { !id && (
                <CreateFooter
                    canBeQvFilter={canBeQvFilter}
                    confirmationDialogOpened={modalOpen && updatedState}
                    intent={intent}
                    setId={setId}
                    taxonomyName={taxonomyName}
                    taxonomyNode={taxonomyNode}
                    onAccept={handleAccept}
                    onModalClose={onModalClose}
                    onSave={handleSave}
                />
                )}
            </CSSGrid>
        </Drawer>
    );
};
