import {
    Divider,
    experimentalStyled,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Theme,
    Typography,
} from '@material-ui/core';
import {
    ComponentProps,
    useEffect,
    MouseEvent,
    useState,
    ChangeEvent,
} from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { DetailsDrawer } from 'src/components/common/DetailsDrawer';
import { TaxonomyDetailsForm } from 'src/components/Taxonomies/TaxonomyDetailsDrawer/TaxonomyDetailsForm';
import { useQueryTaxonomy } from 'src/components/Taxonomies/hooks/useQueryTaxonomy';
import { drawerUpdatedState } from 'src/atoms/drawerUpdatedAtom';
import { FormArea } from 'src/components/common/DetailsDrawer/FormArea';
import { SearchPanels } from 'src/components/common/TaxonomyDiscovery/SearchPanels';
import { DraggableType } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/DraggableType';
import { SingleTaxonomyTreeView } from 'src/components/common/TaxonomyDiscovery/TaxonomyTree/SingleTaxonomyTreeView';
import { TaxonomyDrawer } from 'src/components/Taxonomies/TaxonomyDetailsDrawer/TaxonomyDrawer';
import { TaxonomyIntent } from 'src/constants';

import { isTaxonomyValidState } from 'src/atoms/taxonomyAtom';

type TaxonomyNode = Models.ContentStoreApi.V3.TaxonomyNode;

export interface PropTypes extends Omit<ComponentProps<typeof DetailsDrawer>, 'id' | 'onDelete' | 'onSave'> {
    id?: string | null;
    preloadDataId?: string | null;
    setId: (id: string) => void;
}

const StyledTableContainer = experimentalStyled(TableContainer)(({ theme }) => ({
    marginTop: theme.spacing(6),
}));

const useStyles = makeStyles((theme: Theme) => ({
    divider: {
        marginBottom: theme.spacing(6),
    },
    drawer: {
        padding: `${theme.spacing(6)} ${theme.spacing(6)} 0 ${theme.spacing(6)}`,
        maxWidth: '100%',
        minWidth: '100%',
        overflowY: 'hidden',
        width: '100%',
        [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
            maxWidth: theme.spacing(300),
            minWidth: theme.spacing(280),
            width: 'auto',
        },
        backgroundColor: '#f8f8f8',
    },
    grid: {
        height: '100%',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        gridTemplateRows: 'auto 1fr',
        gridAutoFlow: 'row dense',
    },
    taxonomyHeaders: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        fontWeight: 'bold',
        variant: 'body1',
    },
    taxonomyGrid: {
        marginTop: theme.spacing(3),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
}));

const manageTaxonomyTooltipTitle = (
    <>
        <Typography component="p" variant="caption">
            Drag categories from the left widget to the right area to edit your taxonomy.
        </Typography>
        <Typography component="p" variant="caption">
            Hover over the desired area to substitute your elements or move them above,
            below or under another category.
        </Typography>
        <Typography component="p" variant="caption"> Note: Only one node can be placed at level 0.</Typography>
    </>
);

export const TaxonomyDetailsDrawer = (props: PropTypes): JSX.Element => {
    const {
        id = null,
        preloadDataId = null,
        setId,
        open,
        onClose,
        ...rest
    } = props;
    const classes = useStyles();

    const accessToken = useRecoilValue(isAuthorizedQuery) as string;
    const [taxonomyName, setTaxonomyName] = useState<string>('');
    const [intent, setIntent] = useState<TaxonomyIntent|undefined>(undefined);
    const [canBeQvFilter, setCanBeQvFilter] = useState<boolean>(false);
    const [rootNode, setRootNode] = useState<TaxonomyNode[]>([]);

    const setUpdatedState = useSetRecoilState(drawerUpdatedState);
    const setIsTaxonomyValid = useSetRecoilState(isTaxonomyValidState);

    const {
        data,
    } = useQueryTaxonomy(accessToken, (id ?? preloadDataId) as string, {
        suspense: false,
        refetchInterval: false,
        enabled: id !== null || preloadDataId !== null,
    });

    const isFacetedTaxonomy = data?.taxonomyNode.children?.some(
        (child: TaxonomyNode) => child.children?.length,
    ) ?? false;

    useEffect(() => {
        if (data && id !== null) {
            setTaxonomyName(data.taxonomyName);
            setRootNode([{ ...data.taxonomyNode }]);
            setCanBeQvFilter(data.canBeQvFilter);
        } else if (data && id === null && preloadDataId !== null) {
            setTaxonomyName(`${data.taxonomyName} - Clone`);
            setRootNode([{ ...data.taxonomyNode }]);
        }
        if (data?.intent) {
            setIntent(data.intent as TaxonomyIntent);
        }
    }, [data, id, preloadDataId]);

    useEffect(() => {
        setIsTaxonomyValid(!!taxonomyName && !!intent && rootNode.length > 0);
    }, [taxonomyName, intent, setIsTaxonomyValid, rootNode]);

    const handleClose = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        if (onClose) {
            onClose(event, 'escapeKeyDown');
            setTaxonomyName('');
            setRootNode([]);
            setIntent(undefined);
            setCanBeQvFilter(false);
        }
    };

    const handleNameChange = (newTaxonomyName: string): void => {
        setTaxonomyName(newTaxonomyName);
        setUpdatedState(true);
    };

    const handleIntentChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setIntent(event.target.value as TaxonomyIntent);
        setUpdatedState(true);
    };

    const handleCanBeQvFilterChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setCanBeQvFilter(event.target.checked);
    };

    return (
        <TaxonomyDrawer
            canBeQvFilter={canBeQvFilter}
            id={id}
            intent={intent}
            open={open}
            PaperProps={{ classes: { root: classes.drawer } }}
            setId={setId}
            taxonomyName={taxonomyName}
            taxonomyNode={rootNode}
            onClose={handleClose}
            {...rest}
        >
            <FormArea title="Taxonomy Details" tooltip={manageTaxonomyTooltipTitle}>
                <TaxonomyDetailsForm
                    canBeQvFilter={canBeQvFilter}
                    id={id}
                    intent={intent}
                    isFacetedTaxonomy={isFacetedTaxonomy}
                    taxonomyName={taxonomyName}
                    onIntentChange={handleIntentChange}
                    onNameChange={handleNameChange}
                    onQvFilterChange={handleCanBeQvFilterChange}
                />
            </FormArea>
            <Divider className={classes.divider} />
            <Grid container spacing={2}>
                <DndProvider backend={HTML5Backend}>
                    <Grid item xs={6}>
                        <SearchPanels
                            className={classes.taxonomyGrid}
                            draggable={DraggableType.External}
                            selectable={false}
                            value={[]}
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onChange={(): void => {}}
                        />
                    </Grid>
                    <Grid item className={classes.grid} xs={6}>
                        <SingleTaxonomyTreeView
                            className={classes.taxonomyGrid}
                            node={rootNode}
                            setData={setRootNode}
                        />
                    </Grid>
                </DndProvider>
            </Grid>
            <StyledTableContainer>
                <Table stickyHeader size="small">
                    <TableHead />
                    <TableBody />
                </Table>
            </StyledTableContainer>
        </TaxonomyDrawer>
    );
};
